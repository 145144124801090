
import { Component, Prop, Vue } from "vue-property-decorator";
import { ProductSearchResultProduct } from '../../models/Product';
import LoadingModule from '../../store/modules/LoadingModule';
import UserModule from '../../store/modules/UserModule';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import { SubscriptionProduct, SubscriptionProductRequest } from "@/models/Subscription";
import { CbxVariablesModule } from "@/store/modules/CbxVariablesModule";
import SubscriptionModule from "@/store/modules/SubscriptionModule";
import CbxDatePicker from "../utils/CbxDatePicker.vue";

@Component({
    name: "DetailcreateSubscription",
    components: {
        CbxDatePicker,
    }
})
export default class DetailcreateSubscription extends Vue {
    @Prop({ type: String, required: false, default: '' }) private size: string;

    modalOpen: boolean = false;
    model: SubscriptionProductRequest = null;
    status: any = {
        error: false,
        success: false,
    };

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get isSignedIn(): boolean {
        return UserModule.IS_SIGNED_IN;
    }
    
    get product(): ProductSearchResultProduct {
        return ProductDetailsModule.PRODUCT;
    }

    get sku(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.sku;
        }
        return this.product?.id;
    }

    get colli(): number {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.colli;
        }
        return this.product?.colli;
    }

    get subscriptionItems(): SubscriptionProduct[] {
        return SubscriptionModule.SUBSCRIPTION_ITEMS;
    }

    get hasActiveSubscription(): boolean {
        return this.subscriptionItems.some(si => si.productid == this.sku);
    }

    get customerDeliveryDays(): number[] {
        return CbxVariablesModule.VARIABLES.customerDeliveryDays;
    }
    get weekdays(): any[] {
        return [
            {
                id: 1,
                label: this.$t('general.monday', ['Monday']),
            },
            {
                id: 2,
                label: this.$t('general.tuesday', ['Tuesday']),
            },
            {
                id: 3,
                label: this.$t('general.wednesday', ['Wednesday']),
            },
            {
                id: 4,
                label: this.$t('general.thursday', ['Thursday']),
            },
            {
                id: 5,
                label: this.$t('general.friday', ['Friday']),
            },
        ].filter(wd => this.customerDeliveryDays.includes(wd.id));
    }
    get disabledDates(): any {
        return {
            mindate: new Date(new Date().setDate(new Date().getDate() + 2)),
            maxdate: new Date(new Date().setDate(new Date().getDate() + 90)),
            disabledweekdays: [0, 1, 2, 3, 4, 5, 6].filter(d => d != this.model.deliveryday),
            disableddates: [],
        };
    }

    decrement() {
        // respect colli
        this.model.quantity = Math.floor(this.model.quantity / this.colli) * this.colli;

        this.model.quantity -= this.colli;
    }

    increment() {
        // respect colli
        this.model.quantity = Math.floor(this.model.quantity / this.colli) * this.colli;

        this.model.quantity += this.colli;
    }

    async modalShow() {
        await SubscriptionModule.GET_SUBSCRIPTION_ITEMS();

        this.model = {
            productid: this.sku,
            quantity: this.colli,
            deliveryday: this.weekdays[0]?.id,
            deliveryweekspan: 1,
            startdate: null,
        };
        this.modalOpen = true;
    }

    modalHide() {
        this.modalOpen = false;
    }

    async add() {
        LoadingModule.SET_LOADING(true);
        const response = await SubscriptionModule.ADD_SUBSCRIPTION_ITEM(this.model);
        this.status.error = !response;
        this.status.success = response;
        LoadingModule.SET_LOADING(false);
    }
}
