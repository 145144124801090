import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store';
import ProductService from '@/services/ProductService';
import { ProductSearchResultProduct } from '@/models/Product';
import SubscriptionService from '@/services/SubscriptionService';
import { SubscriptionProduct, SubscriptionProductRequest } from '@/models/Subscription';

@Module({
    store,
    dynamic: true,
    name: 'subscription',
    namespaced: true,
})
class SubscriptionModule extends VuexModule {
    private ss: SubscriptionService = new SubscriptionService();
    private ps: ProductService = new ProductService();

    private subscriptionItems: SubscriptionProduct[] = [];
    private searchResults: ProductSearchResultProduct[] = [];
    private focusSearchInput: number = 0;

    get SUBSCRIPTION_ITEMS(): SubscriptionProduct[] {
        return this.subscriptionItems;
    }

    get SEARCH_RESULTS(): ProductSearchResultProduct[] {
        return this.searchResults;
    }
    
    get FOCUS_SEARCH_INPUT(): number {
        return this.focusSearchInput;
    }

    @Action({ commit: 'SET_SUBSCRIPTION_ITEMS'})
    public GET_SUBSCRIPTION_ITEMS() {
        return this.ss.getproducts();
    }
    
    @Mutation
    private SET_SUBSCRIPTION_ITEMS(payload: SubscriptionProduct[]) {
        this.subscriptionItems = payload;
    }

    @Action
    public ADD_SUBSCRIPTION_ITEM(payload: SubscriptionProductRequest) {
        return this.ss.add(payload);
    }

    @Action({ commit: 'SET_SEARCH_RESULTS'})
    public async SEARCH_PRODUCTS(payload: string) {
        return await this.ps.search(payload)
    }

    @Action
    public async UPDATE_SUBSCRIPTION_ITEM(payload: SubscriptionProductRequest) {
        return await this.ss.update(payload.id, payload);
    }

    @Mutation
    public async REMOVE_SUBSCRIPTION_ITEM(id: number) {
        const result = await this.ss.remove(id);
        if (result)
            this.subscriptionItems.splice(this.subscriptionItems.findIndex((x: SubscriptionProduct) => x.id === id), 1);
    }

    @Mutation
    public SET_SEARCH_RESULTS(products: ProductSearchResultProduct[]) {
        this.searchResults = products;
    }
    
    @Mutation
    public SET_INPUT_FOCUS() {
        this.focusSearchInput++;
    }
}

export default getModule(SubscriptionModule);
