import axios, { AxiosResponse } from 'axios';
import { SubscriptionProductRequest, SubscriptionProduct } from '@/models/Subscription';

export default class SubscriptionService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/subscription`;
    }

    public async getproducts(): Promise<SubscriptionProduct[]> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async remove(id: number): Promise<boolean> {
        try {
            await axios.delete(`${this.endpoint}/${id}`);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async add(payload: SubscriptionProductRequest): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}`, payload);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async update(id: number, payload: SubscriptionProductRequest): Promise<boolean> {
        try {
            await axios.post(`${this.endpoint}/${id}`, payload);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
